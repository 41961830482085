import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { durationInMinutes } from "../../utils";
import style from "./style.module.css";
import { getBackgroundStyleForCard } from '../../storyboardUtils';

export const Card = ({ className = '', show = true, time = null, title = '', description = '', onClick = () => { }, transitionDelay = {}, highStoryboard = [], position }) => {
  return (
    <section class={`${style.cardContainer} ${className} ${show ? '' : style.hide}`} style={{ transitionDelay: `${transitionDelay}ms` }} onClick={onClick}>
      <div class={style.thumbnailContainer} style={getBackgroundStyleForCard(position, highStoryboard)} >
        {time !== null ? <p>{durationInMinutes(time)}</p> : null}
      </div>
      <div class={style.descriptionContainer}>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </section>
  );
};
export default withTranslation()(Card);

Card.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  thumbnailCard: PropTypes.string,
  time: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};
