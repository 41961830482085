export const URL_API = process.env.URL_API;
export const URL_SHARE = process.env.URL_SHARE;
export const URL_TEESTER = process.env.URL_TEESTER;
export const URL_IMAGE = process.env.URL_IMAGE;

// The CDN will cache the API request based on this value (in sec)
export const CDN_CACHE_DELAY = 0.25 * 60 * 60;

export const ZENDESK_FR = process.env.ZENDESK_FR;
export const ZENDESK_EN = process.env.ZENDESK_EN;

export const REPORT_LINK = process.env.REPORT_LINK;

export const ENABLE_REPORT_LINK = process.env.ENABLE_REPORT_LINK === 'true';

export const NODE_ENV = process.env.NODE_ENV;
