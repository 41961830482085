// Don't remove the next line. Needed by the test to render virtual dom
import { h, Fragment } from 'preact';

import { useState, useRef, useCallback, useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';

import {
  getFromLocalStorage,
  onFullScreenEnter,
  onFullScreenExit,
  PLAYER_MODE,
  removeFromLocalStorage,
  setToLocalStorage,
} from '../../utils';
import LogoPlayer from '../LogoPlayer';
import AddToBasketButton from '../AddToBasketButton';
import CardsList from '../CardsList';
import PlayerActionContainer from '../PlayerActionContainer';
import Video from '../Video';
import SubtitlesContainer from '../SubtitlesContainer';
import Loader from '../Loader';
import Foreground from '../Foreground';
import ForegroundImage from '../ForegroundImage';
import MediaMetadata from '../MediaMetadata';
import EndMenu from '../EndMenu';
import Thumbnail from '../Thumbnail';
import LanguagesModal from '../LanguagesModal';
import ErrorNotFound from '../ErrorNotFound';
import TapToUnmute from '../TapToUnmute';
import { getLanguage } from '../../i18n';
import { getReportLink } from '../../utils/links';

import style from './style.module.css';
import './font.module.css';

const PlayerContainer = ({
  className = '',

  id = '',
  url = '',
  type = 'DIRECT',
  logoUrl = '',

  title = '',
  /*description = '',*/
  videoLanguage = 'en',

  /*thumbnail = '',*/
  metaThumbnail = '',
  firstFrameUrl = '',
  lastFrameUrl = '',
  introsVideo = null,

  lowStoryboard = [],
  highStoryboard = [],

  color = '',
  thumbnailColorTopText = '',
  thumbnailColorTopBackground = '',
  thumbnailColorBottomText = '',
  thumbnailColorBottomBackground = '',

  thumbnailText1 = '',
  thumbnailText2 = '',
  companyName = '',
  ratio = '',

  clientName = '',
  platformName = '',
  creatorName = '',

  onEvent = () => { },
  onInit = () => { },

  allowBuy = true,
  allowRating = true,
  allowShare = true,
  allowJoinCommunity = true,

  joinUrl = '',
  productPageUrl = '',
  shareUrl = '',
  productReferences = [],

  musicUrl = '',
  musicVolume = 0.1,

  autoPlay = false,

  showSkeepersLogo = true,
  customLogoUrl = null,

  subtitles = [],

  cards = [],

  isMobile = false,
  isIos = false,

  canAutoPlayWithSound = false,
  forceSubtitle = false,
  enableSmartBorders = true,

  testedAt,
  releasedAt,
  customLogoAltText,
  mode,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showEndMenu, setShowEndMenu] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMuted, setIsMuted] = useState(autoPlay && !canAutoPlayWithSound);
  const [displayTapToUnmute, setDisplayTapToUnmute] = useState(autoPlay && !canAutoPlayWithSound && isMobile);
  const [showLanguageModal, setShowModalLangue] = useState(false);
  const [showInterface, setShowInterface] = useState(!isMobile); // By default only show interface on desktop
  const [isBuffering, setIsBuffering] = useState(false);
  const [currentSubtitlesId, setCurrentSubtitlesId] = useState(false);
  const [subtitlesChanged, setSubtitlesChanged] = useState(false);
  const [thumbnailToogle, setThumbnailToogle] = useState(true);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const playerRef = useRef(null);
  const containerRef = useRef(null);
  const [hiddenThumbnailContainer, setHiddenThumbnailContainer] = useState(true);
  const [maxCurrentTime, setMaxCurrentTime] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const [showPlayIconIndicator, setShowPlayIconIndicator] = useState(false);
  const [foregroundStoryboard, setForegroundStoryboard] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [showLegalBanner, setShowLegalBanner] = useState(false);
  const videoElement = playerRef.current?.ref?.current;
  const fullScreenDisabled = isIos ? !videoElement?.webkitSupportsFullscreen : !document.fullscreenEnabled;

  const clickToUnmute = (e) => {
    onUnmute();
    setShowInterface(false);
    setDisplayTapToUnmute(false);
    e.preventDefault();
  };

  const onTrackingEvent = (event, arg) => {
    onEvent(event, id, arg);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tooglePlaying = () => {
    setShowPlayIconIndicator(!showPlayIconIndicator);
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
  };

  /** Allows to go to the right timecode of the video **/
  const seekTo = (time, cardId = null) => {
    if (cardId) {
      onTrackingEvent('SEEK_CARD', cardId.id);
    }
    playerRef.current.seekTo(time);
    if (!isPlaying) {
      playerRef.current.play();
      setShowEndMenu(false);
    }
  };

  const seekToCard = (time, cardId) => {
    seekTo(time);
    onTrackingEvent('SEEK_CARD', cardId);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRefresh = () => {
    playerRef.current.play();
    setShowEndMenu(false);
    setShowInterface(true);
    onUnmute();
  };

  const onSubtitlesChange = (subtitleId) => {
    setCurrentSubtitlesId(subtitleId);
    setSubtitlesChanged(true);
    setShowModalLangue(false);
    onTrackingEvent('SUBTITLE', subtitleId);
    if (subtitleId) {
      setToLocalStorage('subtitleLanguage', subtitles.find((s) => s.id === subtitleId).language || false);
    } else {
      removeFromLocalStorage('subtitleLanguage');
    }
  };

  const onLanguages = () => {
    setShowModalLangue(!showLanguageModal);
  };

  const onMute = () => {
    playerRef.current.setVolume(0);
    setIsMuted(true);
    onTrackingEvent('SOUND_OFF');
    autoSelectSubtitle();
  };

  const onUnmute = () => {
    playerRef.current.setVolume(1);
    onTrackingEvent('SOUND_ON');
    setIsMuted(false);
  };

  /** Makes it possible to turn the sound on or off **/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toogleSound = () => {
    if (isMuted) {
      onUnmute();
    } else {
      onMute();
    }
  };

  /** Allows you to put in full screen **/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toogleFullScreen = () => {
    if (isFullScreen) {
      onFullScreenExit();
      onTrackingEvent('FULL_SCREEN_LEAVE');
    } else {
      if (isIos) {
        videoElement?.webkitEnterFullscreen();
      } else {
        onFullScreenEnter(containerRef.current);
      }
      onTrackingEvent('FULL_SCREEN_ENTER');
    }
  };

  //Hide the legal banner.
  const onHideLegalBanner = () => setShowLegalBanner(false);

  const onPlay = (progress) => {
    /* Close the popup Languagues if it's open */
    if (thumbnailToogle) {
      handleToogleThumbnail();
    }
    if (showLanguageModal) {
      setShowModalLangue(false);
    }
    if (!isPlaying) {
      onTrackingEvent('PLAY', {
        percent: Math.round(progress.played * 100),
        second: Math.round(progress.playedSeconds),
      });
    }
    setIsPlaying(true);

    if (!subtitlesChanged) {
      autoSelectSubtitle();
    }
    //Hide the legal banner 4s after the beggining of the video
    setTimeout(onHideLegalBanner, 4000);
  };

  const onPause = (progress) => {
    /* Close the popup Languagues if it's open */
    if (showLanguageModal) {
      setShowModalLangue(false);
    }
    if (isPlaying) {
      onTrackingEvent('PAUSE', {
        percent: Math.round(progress.played * 100),
        second: Math.round(progress.playedSeconds),
      });
    }
    setIsPlaying(false);
  };

  const onEnded = () => {
    setIsPlaying(false);
    setCurrentTime(0);
    setShowEndMenu(true);
    setShowInterface(false);
    onTrackingEvent('END');
  };

  const onRate = (rating) => {
    onTrackingEvent('RATING', rating);
  };

  const onShare = () => {
    onTrackingEvent('SHARE');
  };

  const onCommunityJoin = () => {
    onTrackingEvent('CTA_JOIN_COMMUNITY');
  };

  const onProgress = (progress) => {
    if (progress.playedSeconds !== currentTime) {
      setCurrentTime(progress.playedSeconds);
      if (progress.playedSeconds > maxCurrentTime) {
        setMaxCurrentTime(progress.playedSeconds);
      }
    }
    onTrackingEvent('PROGRESS', {
      percent: Math.round(progress.played * 100),
      second: Math.round(progress.playedSeconds),
    });
  };

  const onSeek = (progress) => {
    if (progress.playedSeconds !== currentTime) {
      setCurrentTime(progress.playedSeconds);

      onTrackingEvent('SEEK', {
        percent: Math.round(progress.played * 100),
        second: Math.round(progress.playedSeconds),
      });
    }
  };

  const onDuration = (newDuration) => {
    if (duration !== newDuration) {
      setDuration(newDuration);
    }
  };

  const onBuffering = (newIsBuffering) => {
    if (isBuffering !== newIsBuffering) {
      setIsBuffering(newIsBuffering);
      onTrackingEvent('BUFFERING', newIsBuffering);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFastForward = () => {
    playerRef.current.seekTo(currentTime + 5 < duration ? currentTime + 5 : duration);
    if (!isPlaying) {
      playerRef.current.play();
    }
    onTrackingEvent('FAST_FORWARD');
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRewind = () => {
    playerRef.current.seekTo(currentTime - 5 > 0 ? currentTime - 5 : 0);
    if (!isPlaying) {
      playerRef.current.play();
    }
    onTrackingEvent('REWIND');
  };

  const onShowInterface = () => {
    if (showEndMenu) return;
    if (!showInterface) {
      setShowInterface(true);
    }
  };

  const onHideInterface = () => {
    /* Stop hiding the interface if the language modal is open or ModalBasket is open or hovering the component */
    if (showLanguageModal || isHover || isMouseDown) {
      return;
    }
    if (showInterface || showEndMenu) {
      /* False by default. Setting it to true suspends the hideInterface */
      setShowInterface(false);
    }
  };

  const handleToogleThumbnail = () => {
    setThumbnailToogle(false);
    playerRef.current.play();
    onTrackingEvent("PLAY");
    setTimeout(() => {
      /* Hidden all the thumbnail container after 800ms */
      setHiddenThumbnailContainer(false);
    }, 800);
  };

  const onClickBasketButton = () => {
    onTrackingEvent('CTA_BUY_PRODUCT');
    window.open(productPageUrl, '_blank');
    // Auto pause the video
    playerRef.current.pause();
  };

  /* Detect if the mouse is hovering on the component */
  const onMouseVisible = () => {
    setIsHover(true);
  };

  /* Detect if the mouse is no longer hovering on the component */
  const onMouseInvisible = () => {
    setIsHover(false);
  };

  /* Auto select a subtitle track */
  const autoSelectSubtitle = () => {
    const languageSelected = getFromLocalStorage('subtitleLanguage');
    const language = (getLanguage() || navigator.userLanguage || navigator.language || 'en').split('-')[0];

    if (subtitlesChanged || currentSubtitlesId) {
      return;
    }

    // Salty note
    // If forceSubtitle is true, we force subtitle display because a stupid customer asked for it and our team thinks
    // it's smarter than the hundreds of engineers who work on subtitle management in big companies.

    // User selected subtitle in the past, we try to load a subtitle in this language
    if (languageSelected && subtitles.find((s) => s.language === languageSelected)) {
      setCurrentSubtitlesId(subtitles.find((s) => s.language === languageSelected).id || false);
    }
    // If the user language is different than the video language, load subtitle in the user language
    else if (subtitles.find((s) => s.language === language) && language !== videoLanguage) {
      setCurrentSubtitlesId(subtitles.find((s) => s.language === language).id || false);
    }
    // If we are on mobile device or if we mute the video, we try to load the user language subtitle
    else if ((isMuted || isMobile || forceSubtitle) && subtitles.find((s) => s.language === language)) {
      setCurrentSubtitlesId(subtitles.find((s) => s.language === language).id || false);
    }
    // If we are mute and there is only one subtitle track, we load it
    else if ((isMuted || forceSubtitle) && subtitles.length === 1) {
      setCurrentSubtitlesId(subtitles[0].id || false);
    }
    // We are mute, load English as a fallback if we can
    else if (isMuted && subtitles.find((s) => s.language === 'en')) {
      setCurrentSubtitlesId(subtitles.find((s) => s.language === 'en').id);
    }
  };

  const isVertical = ratio === '9:16';
  const canDisplaySubtitles = subtitles.length > 0;

  onInit((action) => {
    if (showEndMenu && action === 'PLAY') {
      onRefresh();
    } else if (action === 'PAUSE') {
      playerRef.current.pause();
    } else if (action === 'PLAY') {
      playerRef.current.play();
    } else if (action === 'MUTE') {
      onMute();
    } else if (action === 'UNMUTE') {
      onUnmute();
    }
  });

  const handleKeyboardNavigation = useCallback((e) => {
    if (showEndMenu && e.key.toLowerCase() === 'r') {
      setShowInterface(true);
      return onRefresh();
    }

    if (!showEndMenu) {
      setShowInterface(true);
      switch (e.key.toLowerCase()) {
        case 'arrowleft':
          onRewind();
          break;
        case 'arrowright':
          onFastForward();
          break;
        case 'm':
          toogleSound();
          break;
        case 'f':
          toogleFullScreen();
          break;
        case ' ':
          if (['FAST_BACK', 'PAUSE', 'PLAY', 'FAST_FORWARD', 'INFO', 'SOUND_OFF', 'SOUND_ON', 'SUBTITLES', 'SHRINK', 'FULL_SCREEN'].includes(e.target.getAttribute('aria-type'))
              || e.target.hasAttribute('aria-subtitleId')
          ) return;
          tooglePlaying();
          break;
      }
    }
  }, [showEndMenu, onRefresh, onRewind, onFastForward, toogleSound, toogleFullScreen, tooglePlaying])

  // We catch this event separatly because the "escape" click is not catch with 'keydown' event
  // if you are in full screen and it not update the isFullScreen state
  const exitHandler = useCallback(() => {
    setIsFullScreen(!isFullScreen);
  }, [setIsFullScreen, isFullScreen]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardNavigation, true);
    document.addEventListener('fullscreenchange', exitHandler);

    return () => {
      document.removeEventListener('keydown', handleKeyboardNavigation, true);
      document.removeEventListener('fullscreenchange', exitHandler);
    }
    //  todo: add memoization for handleKeyboardNavigation function and other functions that are used inside
  }, [handleKeyboardNavigation, exitHandler]);

  const reportLink = getReportLink({ clientName, platformName, creatorName, url, productReferences });

  if (!url) {
    return (
      <>
        <div className={`${style.playerContainer} ${className}`}>
          <ErrorNotFound />
        </div>
      </>
    );
  }

  const showUI = !displayTapToUnmute && showInterface && !hiddenThumbnailContainer;

  //Display the legal banner by clicking on i icon.
  const onDisplayLegalBanner = () => {
    if (showLegalBanner) return;
    setShowLegalBanner(true);
    setTimeout(onHideLegalBanner,   14000);
  };

  onTrackingEvent('LOAD');
  return (
    <>
      <div
        className={`${isVertical ? style.playerContainerVertical : style.playerContainer} ${className}`}
        ref={containerRef}
        style={{
          '--main-color': color,
          '--thumb-top-background-color': thumbnailColorTopBackground,
          '--thumb-top-text-color': thumbnailColorTopText,
          '--thumb-bottom-background-color': thumbnailColorBottomBackground,
          '--thumb-bottom-text-color': thumbnailColorBottomText,
          ...(firstFrameUrl ? { backgroundImage: `url(${firstFrameUrl})` } : {}),
        }}
      >
        {mode === PLAYER_MODE.preview && (
            <Thumbnail
                firstFrameUrl={firstFrameUrl}
                introsVideo={introsVideo}
                showVideoPreview
                isVertical={isVertical}
            />
        )}
        {mode === PLAYER_MODE.default && (
          <>
            <MediaMetadata
                isPlaying={isPlaying}
                image={metaThumbnail}
                title={title}
                subtitle={companyName}
                onPlay={onPlay}
                onPause={onPause}
            />
            <Video
                ref={playerRef}
                url={url}
                backgroundUrl={enableSmartBorders ? lastFrameUrl : ''}
                type={type}
                onPlay={onPlay}
                onPause={onPause}
                onProgress={onProgress}
                onDuration={onDuration}
                onBuffering={onBuffering}
                onSeek={onSeek}
                onEnded={onEnded}
                autoPlay={autoPlay}
                musicUrl={musicUrl}
                musicVolume={musicVolume}
                initialMute={autoPlay && !canAutoPlayWithSound}
                onPlayingEvent={() => setForegroundStoryboard(null)}
            />
            {!showEndMenu && displayTapToUnmute && <TapToUnmute onClick={clickToUnmute} />}
            <Loader show={isPlaying && isBuffering} />
            {!autoPlay && (
                <Thumbnail
                    onClick={handleToogleThumbnail}
                    thumbnailText1={thumbnailText1}
                    thumbnailText2={thumbnailText2}
                    firstFrameUrl={firstFrameUrl}
                    thumbnailToogle={thumbnailToogle}
                    hidden={hiddenThumbnailContainer}
                    introsVideo={introsVideo}
                    onMouseVisible={onMouseVisible}
                    onMouseInvisible={onMouseInvisible}
                    showVideoPreview={isHover}
                    isVertical={isVertical}
                />
            )}
            <ForegroundImage storyboard={foregroundStoryboard} />
            <Foreground
                show={showUI}
                isMobile={isMobile}
                showPlayIconIndicator={showPlayIconIndicator}
                isPlaying={isPlaying}
                onPlay={tooglePlaying}
                onHideInterface={onHideInterface}
                onShowInterface={onShowInterface}
            />
            {showSkeepersLogo && !thumbnailToogle && !showEndMenu && (
                <LogoPlayer
                    show={showUI}
                    color='white'
                    titleAccessibility='Skeepers'
                    logoUrl={logoUrl}
                    customLogoUrl={customLogoUrl}
                    customLogoAltText={customLogoAltText}
                    isVertical={isVertical}
                />
            )}
            {!showEndMenu && !thumbnailToogle && (
                <CardsList
                    testedAt={testedAt}
                    releasedAt={releasedAt}
                    show={showInterface}
                    showLegalBanner={showLegalBanner}
                    cards={cards}
                    onClick={seekToCard}
                    currentTime={currentTime}
                    onMouseVisible={onMouseVisible}
                    onMouseInvisible={onMouseInvisible}
                    thumbnailToogle={thumbnailToogle}
                    highStoryboard={highStoryboard}
                    reportLink={reportLink}
                />
            )}
            {allowBuy && productPageUrl && <AddToBasketButton show={showInterface} onClick={onClickBasketButton} />}
            <EndMenu
                shareUrl={shareUrl}
                showEndMenu={showEndMenu}
                onRefresh={onRefresh}
                joinUrl={joinUrl}
                productPageUrl={productPageUrl}
                companyName={companyName}
                lastFrameUrl={lastFrameUrl}
                allowRating={allowRating}
                allowJoinCommunity={allowJoinCommunity}
                allowShare={allowShare}
                allowBuy={allowBuy}
                onClickBasketButton={onClickBasketButton}
                urlVideo={url}
                onRate={onRate}
                onShare={onShare}
                onCommunityJoin={onCommunityJoin}
                isMobile={isMobile}
            />
            {currentTime !== 0 && currentSubtitlesId && (
                <SubtitlesContainer
                    currentTime={currentTime}
                    show={showInterface}
                    url={subtitles.find((subtitle) => subtitle.id === currentSubtitlesId).srtUrl}
                />
            )}
            {canDisplaySubtitles && (
                <LanguagesModal
                    show={showLanguageModal}
                    subtitles={subtitles}
                    onSubtitlesChange={onSubtitlesChange}
                    selectedSubtitle={currentSubtitlesId}
                    onLanguages={onLanguages}
                />
            )}
            <PlayerActionContainer
                cards={cards}
                currentTime={currentTime}
                duration={duration}
                isPlaying={isPlaying}
                isMobile={isMobile}
                fullScreenDisabled={fullScreenDisabled}
                isMuted={isMuted}
                isFullScreen={isFullScreen}
                onPlay={tooglePlaying}
                onRewind={onRewind}
                onFastForward={onFastForward}
                show={showUI}
                onSound={toogleSound}
                onSubtitles={onLanguages}
                onFullScreen={toogleFullScreen}
                onClick={seekTo}
                subtitles={subtitles}
                showLanguageModal={showLanguageModal}
                onMouseVisible={onMouseVisible}
                onMouseInvisible={onMouseInvisible}
                lowStoryboard={lowStoryboard}
                highStoryboard={highStoryboard}
                setForegroundStoryboard={setForegroundStoryboard}
                isMouseDown={isMouseDown}
                setIsMouseDown={setIsMouseDown}
                onDisplayLegalBanner={onDisplayLegalBanner}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PlayerContainer;

PlayerContainer.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  url: PropTypes.string,
  logoUrl: PropTypes.string,
  type: PropTypes.oneOf(['DASH', 'DIRECT', 'HLS']),
  title: PropTypes.string,
  description: PropTypes.string,
  firstFrameUrl: PropTypes.string,
  lastFrameUrl: PropTypes.string,
  introsVideo: PropTypes.array,
  color: PropTypes.string,
  thumbnailText1: PropTypes.string,
  thumbnailText2: PropTypes.string,
  musicUrl: PropTypes.string,
  musicVolume: PropTypes.number,
  productPageUrl: PropTypes.string,
  onEvent: PropTypes.func,
  allowRating: PropTypes.bool,
  allowJoinCommunity: PropTypes.bool,
  allowShare: PropTypes.bool,
  allowBuy: PropTypes.bool,
  joinUrl: PropTypes.string,
  autoPlay: PropTypes.bool,
  showSkeepersLogo: PropTypes.bool,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      time: PropTypes.number,
    })
  ),
  subtitles: PropTypes.array,
  mode: PropTypes.oneOf(Object.values(PLAYER_MODE)),

  clientName: PropTypes.string,
  platformName: PropTypes.string,
  creatorName: PropTypes.string,

  productReferences: PropTypes.array,
};
